<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div v-if="isLoading">
        <Loading />
      </div>
      <div v-else id="body-content-area">
        <form @submit="handleFormSubmit" id="pagelayout">
          <div class="columns">
            <div class="column is-two-thirds-desktop">
              <div class="block" style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;">
                <span class="has-text-weight-bold">Documents</span>
              </div>
              <div class="block">
                <div>
                  <div style="width: 100%; padding-left: 1rem;" v-if="fileError.length !== 0">
                    <div v-for="error in fileError" :key="error" class="has-text-danger">
                      <div style="min-width: 100%!important; margin: .25rem 0rem;">Error: {{error}}</div>
                    </div>
                  </div>
                  <div v-if="groups.length > 0" ref="filePondWrapper"  :class="['filePondWrapper', $refs.filePond && $refs.filePond.getFiles().length < 1 ? 'has-no-files' : '']" @click.stop="openFileBrowser">
                    <file-pond
                      name="file"
                      ref="filePond"
                      :label-idle="filePondLabel"
                      allow-multiple="true"
                      accepted-file-types="application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      :files="input.files"
                      v-on:addfilestart="addfile"
                      v-on:removefile="removefile"
                      :dropOnElement="false"
                      :dropOnPage="true"
                      :credits="[]"
                    />

                  </div>
                </div>
              </div>
            </div>
            <div class="column is-one-third-desktop">
              <div class="block" style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;">
                <span class="has-text-weight-bold">Documents</span>
              </div>
              <div class="block">
                <div>
                  <div  class="field">
                    <div class="control">
                      <Checkbox :isChecked="input.shouldSendNotification" @isChecked="handleIsChecked" :label="'Send email notification'"/>
                    </div>
                  </div>
                  <div  class="field" v-if="input.shouldSendNotification && emailTemplates">
                    <label class="label is-size-7">
                      Email Template
                      <span class="has-text-danger" style="padding-left: 3px;">*</span>
                    </label>
                    <div class="control">
                      <Select :options="emailTemplates" style="max-width: 75%; width: 100%;" ref="emailTemplate" identifier="emailTemplate" @onchange="handleSelectChange"/>
                    </div>
                  </div>
                </div>
                <div class="has-background-warning has-text-white" style="padding: 0.5rem 1rem 1rem 1rem; margin-top: 1rem;" v-show="errors.length>0">
                  <span class="has-text-weight-bold">Error</span>
                  <ul id="example-1">
                    <li style="margin-top: 5px;" v-for="(err,idx) in errors" :key="err + idx">{{ err }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="buttongroup" style="padding-top: 20px;">
            <div class="field is-grouped">
              <div class="control">
                <button :disabled="processing || input.files.length < 1 || !$hasPermissions(clientSession, ['DOCUMENT_CENTER'], 2)" @click.prevent="handleFormSubmit" class="button is-accent has-text-white">
                  <span v-if="!processing">Save</span>
                  <span v-if="processing"><spin-loader/></span>
                </button>
              </div>
              <div class="control">
                <a class="button is-light" v-on:click="resetForm();$router.go(-1)">Cancel</a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from './Loading'
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'

import vueFilePond, { setOptions } from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileRename from 'filepond-plugin-file-rename'

import Constants from '../constants.js'
import Select from './partials/Select.vue'
import Checkbox from './partials/Checkbox.vue'

import { Toast } from 'buefy/dist/components/toast'
import axios from '../axios'
import debounce from 'debounce'

let pageName = 'New Document'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileRename
)

let uploadedFiles = []
const setCompOptions = () => {
  setOptions({
    instantUpload: false,
    allowProcess: false,
    allowRevert: false,
    server: {
      url: `${process.env.VUE_APP_API_URL}`,
      process: {
        url: `/documents`,
        method: 'POST',
        withCredentials: true,
        onload: response => {
          let r = JSON.parse(response)
          uploadedFiles.push(r)
          return r.id
        },
        onerror: response => {
          let res = JSON.parse(response)
          let msg = `Upload Error: ${res.message}`
          Toast.open(msg, 'is-danger', 'is-top', 5000)
        }
      },
      revert: (uniqueFileId, load, error) => {
        let path = `/documents/${uniqueFileId}`
        axios
          .delete(path)
          .then(res => {
            load()
          })
          .catch(err => {
            error(err)
          })
      },
      fetch: null,
      restore: null,
      load: null
    }
  })
}

export default {
  components: {
    Loading,
    Select,
    Checkbox,
    FilePond
  },
  data () {
    return {
      processing: false,
      isLoading: false,
      Constants: Constants,
      pageheading: pageName,
      reportTypesArray: [],
      reportTypesMap: new Map(),
      reportTypes: [],
      fileError: [],
      errors: [],
      groups: [],
      emailTemplates: null,
      filePondLabel: `<div style='color: #7fb942; padding-top: 1rem;'><div><span class='icon bcc-green' style='font-size: 3rem;'><i class='fas drop fa-3x fa-cloud-upload-alt' style='max-width: 50px!important; max-height: 50px!important; font-size: 1rem!important;'></i></span></div><div>Click to choose files to upload, or drop to upload them to the portal instantly</div></div>`,
      input: {
        files: [],
        shouldSendNotification: true,
        emailTemplate: null
      }
    }
  },
  watch: {
    input: {
      handler (oldFile, newFile) {
        if (newFile.files.length !== 0) {
          this.filePondLabel = `<div class='link' style='color: #7fb942;'>Click to add more files</div>`
        } else {
          this.filePondLabel = `<div class='link' style='color: #7fb942;padding-bottom: 3rem;'><div><span class='icon bcc-green' style='font-size: 10rem; padding-bottom: 20px;'><i class='fas drop fa-3x fa-cloud-upload-alt' style='max-width: 100px!important; max-height: 100px!important; font-size: 4rem!important;'></i></span></div><div>Click to choose files to upload, or drop to upload them to the portal instantly</div></div>`
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    }
  },
  beforeRouteLeave (to, from, next) {
    this.beforeWindowUnload(null, next)
  },
  created () {
    window.addEventListener('beforeunload', this.shouldLeave)
    setCompOptions()
    this.getReportTypes()
    this.getGroups()
    this.getEmailTemplates()
  },
  methods: {
    openFileBrowser () {
      if (this.$refs.filePond.getFiles().length > 0) {
        debounce(this.$refs.filePond.browse(), 500)
      }
    },
    // async handleReportTypesSubmit () {
    //   let code = this.inputDefault.code
    //   let route = `/report-types/${code}`
    //   await this.handleSubmit(route, -1, true, true, `The Report Type Code '${this.input.code}' already exists. Please enter a unique code.`)
    // },
    async getGroups () {
      let response = await this.axios.get('carriers?page=1&perPage=1000&sortBy=code')

      if (response.status === 200) {
        this.groups = response.data.records
      }
    },
    confirmLeave () {
      return confirm(this.processing ? 'Do you really want to leave? Files are still processing.' : 'Do you really want to leave? You have unsaved changes!')
    },
    shouldLeave (e) {
      let shouldLeave = true

      if ((this.input.files.length > 0)) {
        if (!this.confirmLeave()) {
          if (e !== null) {
            // Cancel the event
            e.preventDefault()
            // Chrome requires returnValue to be set
            e.returnValue = ''
          }

          shouldLeave = false
        }
      }

      return shouldLeave
    },
    beforeWindowUnload (e, next) {
      let shouldLeave = (this.input.files.length > 0) && !this.shouldLeave()
      if (shouldLeave) {
        next(false)
      } else {
        next()
      }
    },
    async handleFormSubmit () {
      let that = this
      this.processing = true
      let files = this.$refs.filePond.getFiles()
      if (files.length > 0) {
        this.$refs.filePond.processFiles().then(() => {
          let documentIds = []

          uploadedFiles.map((_file) => documentIds.push(_file.id))

          let query = `documents/publish?`

          for (let i = 0; i < documentIds.length; i++) {
            let documentId = documentIds[i]
            if (i === 0) {
              query += `ids=${encodeURIComponent(documentId)}`
            } else {
              query += `&ids=${encodeURIComponent(documentId)}`
            }
          }

          if (that.input.shouldSendNotification) {
            query += `&emailTemplateId=${that.input.emailTemplate}`
          }

          that.axios.post(query).then(() => {
            that.input.files = []
            that.$router.go(-1)
          })
        })
      }
    },
    handleSelectChange (select) {
      if (select.identifier === 'emailTemplate') {
        this.$set(this.input, 'emailTemplate', select.value)
      }
    },
    handleIsChecked (event) {
      this.input.shouldSendNotification = event.isChecked
    },
    removefile (arg, file) {
      this.input.files = this.input.files.filter(function (current) {
        if (current.name !== file.file.name) {
          return file
        }
      })
    },
    addfile (file) {
      let that = this
      let meta = file.filenameWithoutExtension.replaceAll('—', '-').replaceAll('–', '-').split('-')
      let name = ''
      this.errors = []

      // Do not add the same file twice.
      this.$refs.filePond.getFiles().forEach(function (current) {
        if (current.id !== file.id && current.file.name === file.file.name) {
          that.$refs.filePond.removeFile(current, {})
        }
      })

      /*
        {XXX}-{CCC}-{YY}-{NAME}.{EXT}
        XXX = Carrier ("BCC" or "NML", "MM", etc)
        CCC = Report Type Code
        YY = Year
        NAME = Document Name
        EXT = File Extension
      */

      let isValid = new RegExp(/([a-zA-Z]{2,4})-([a-zA-Z]{3})-([0-9]{2})-([a-zA-Z0-9 ()-]+)(.[.a-zA-Z]{3,4})/g).test(file.filename.replaceAll('—', '-').replaceAll('–', '-'))

      if (isValid && meta.length > 0) {
        const isValidGroup = meta[0] !== undefined && this.groups.filter(function (group) {
          if (meta[0] === group.code) {
            return group
          }
        }).length === 1

        let isValidReportType = meta[1] !== undefined && this.reportTypesArray.filter(function (type) {
          if (meta[1] === type.code && type.category !== 'client') {
            return type
          }
        }).length === 1

        const isValidYear = meta[2] !== undefined && !isNaN(parseInt(meta[2]))

        if (meta.length > 3) {
          for (let i = 3; i < meta.length; i++) {
            name += `${i !== 3 ? '-' : ''} ${meta[i].trim()}`
          }
        }

        let { fileType } = {
          fileGroup: meta[0],
          fileName: name,
          fileType: meta[1],
          fileYear: parseInt(meta[2])
        }

        if (this.reportTypesArray.filter(type => type.code === fileType && type.category !== 'client' && type.category !== 'user' && type.category !== '').length === 0) {
          this.errors.push(file.filename + `: references an invalid document type code "${fileType}" for client documents. This type of document doesn't belong here.`)
          isValidReportType = false // Override the previously set value.
          file.abortLoad()
          that.$refs.filePond.removeFile(file, {})
          return
        }

        const hasName = name !== ''
        const hasExtension = file.fileExtension && file.fileExtension !== ''

        this.handleError(file.filename + ': The specified group does not exist.', isValidGroup)
        this.handleError(file.filename + ': The specified report type does not exist.', isValidReportType)
        this.handleError(file.filename + ': The specified year is not valid.', isValidYear)
        this.handleError(file.filename + ': No name was specified.', hasName)
        this.handleError(file.filename + ': No file extension provided', hasExtension)

        if (
          isValidGroup &&
          isValidReportType &&
          isValidYear &&
          hasName &&
          hasExtension
        ) {
          this.input.files.push(file.file)
        } else {
          file.abortLoad()
          this.errors.push(`${file.filename}: The specified name does not meet naming requirements.`)
        }
      } else {
        that.$refs.filePond.removeFile(file, {})
        this.errors.push(`${file.filename}: The specified name does not meet naming requirements.`)
      }
    },
    async getReportTypes () {
      let collection = {}
      let response = await this.axios.get('report-types', {
        perPage: 1000,
        page: 1
      })

      if (response.status === 200) {
        let types = response.data.records
        this.reportTypesArray = types
        this.reportTypesMap = new Map(types.map(type => [type.code, type]))

        types.forEach(function (type) {
          collection[type.code] = type.name
        })

        this.reportTypes = collection
      }
    },
    handleError (error, isValid) {
      if (this.fileError.indexOf(error) === -1 && !isValid) {
        this.fileError.push(error)
      } else if (this.fileError.indexOf(error) !== -1 && isValid) {
        this.fileError = this.fileError.filter(function (errorString) {
          if (errorString !== error) {
            return errorString
          }
        })
      }
    },
    async getEmailTemplates () {
      let collection = {}
      let response = await this.axios.get('email-templates?perPage=1000&page=1')

      if (response.status === 200) {
        let types = response.data.records

        types.forEach(function (_template) {
          if (_template.type.toLowerCase() === 'reporting') {
            collection[_template.id] = _template.name
          }
        })

        this.$set(this, 'emailTemplates', collection)
      }
    }
  }
}
</script>

<style scoped>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}
.filepond--root {
  height: 500px!important;
}
#pagelayout {
  right: 100%;
  bottom: 0;
}

.filepond--panel-root {
    background-color: #f1f0ef!important;
}

.filepond--drop-label {
    position: absolute;
    bottom: 0;
    top: auto;
    padding-bottom: 3rem;
}

.filepond--list-scroller {
  position: absolute;
  top: 0;
  transform: translate3d(0px, 0px, 0px)!important;
}

.filepond--drip-blob {
    background-color: #999;
}
/* bordered drop area */
.filepond--panel {
    background-color: #f1f0ef!important;
    border: 2px solid #fff;
}

.filepond--credits {
  display: none!important;;
}

.filepond--root {
  height: 500px!important;
  overflow-y: hidden;
}

.filepond--panel-root {
  background-color: #f1f0ef!important;
}

.filepond--root .filepond--drop-label {
    /* min-height: 100%;
    overflow-y: hidden; */
}

.filepond--drop-label.filepond--drop-label label {
    /* display: block;
    margin: 0;
    padding: .5em;
    position: absolute;
    bottom: 0; */
}

.filepond--root .filepond--list-scroller {
    /* margin-top: 1em;
    margin-bottom: 1em;
    margin: 1rem;
    overflow-y: hidden;
    max-height: 90%; */
}

.filePondWrapper {
  background-color: #eeeeee;
  border-radius: .25rem;
  cursor: pointer;
  margin: 1rem 0rem;
  height: 500px;
  cursor: pointer;
}

.filePondWrapper.has-no-files .filepond--browser {
    position: absolute;
    background-color: #eee!important;
    margin: 0;
    padding: 0;
    left: 1em;
    top: 1.75em;
    width: calc(100% - 2em);
    opacity: 0;
    font-size: 0;
    min-height: 100%!important;
    z-index: 500;
}

.filepond--root > .filepond--panel {
    z-index: 2;
    background-color: #eee !important;
    border-radius: .3rem;
}

.filePondWrapper.has-no-files .filepond--drop-label {
  position: absolute!important;
  bottom: 0!important;
}

</style>
